import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineReviews,
} from "react-icons/md";
import { LuAlignHorizontalJustifyStart } from "react-icons/lu";
import "../Admin/Admin.css";
import Navbar from "react-bootstrap/Navbar";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BsPersonFill } from "react-icons/bs";
import { GrOverview } from "react-icons/gr";
import { SiTransmission } from "react-icons/si";
import { MdCategory } from "react-icons/md";
import { MdEvent } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { MdEmojiEvents } from "react-icons/md";
import { HiTrophy } from "react-icons/hi2";
import { GrGallery } from "react-icons/gr";
import { MdGroups2 } from "react-icons/md";
import { MdContactPhone } from "react-icons/md";
import { FaRegistered } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import axios from "axios";

const Side = () => {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [Home, setHome] = useState(false);
  const [Result, setResult] = useState(false);

  // Responsive sidebar
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  //integrating get  method
  const [Addregister, setAddregister] = useState([]);
  const getAddregister = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/user/getregistration"
      );
      if (res.status === 200) {
        setAddregister(res.data.getregistration);
        // setNoChangeData(res.data.getregistration);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddregister();
  }, []);

  //integrating get  method
  const [Addgeneral, setAddgeneral] = useState([]);
  const getgeneral = async () => {
    try {
      let res = await axios.get("https://shuttlesmash.shop/api/user/getgeneral");
      if (res.status === 200) {
        setAddgeneral(res.data.getgeneral);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getgeneral();
  }, []);


    //integrating get  method
    const [AddFeedback, setAddFeedback] = useState([]);
    const getAddFeedback = async () => {
      try {
        let res = await axios.get(
          "https://shuttlesmash.shop/api/user/getfeedback"
        );
        if (res.status === 200) {
          setAddFeedback(res.data.getfeedback);
        }
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getAddFeedback();
    }, []);

  return (
    <div>
      <Navbar expand="lg" className=" p-0">
        <button
          class="custom-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample09"
          aria-controls="navbarsExample09"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
          style={{ margin: "10px" }}
        >
          <span>
            <GiHamburgerMenu style={{ color: "white" }} />
          </span>
        </button>
        <div
          class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarsExample09"
        >
          <div className="si09">
            <div style={{ width: "100%", justifyContent: "space-between" }}>
              <div
                className="lo-ad"
                style={{ background: "white", borderBottom: "1px solid white" }}
              >
                <div className="">
                  {/* <a href="/" className="tail-text"> */}
                  <img
                    src="../Assets/logo.png"
                    alt="Logo"
                    className="admin-logo-img"
                    style={{ width: "100%", height: "7rem" }}
                  />
                  {/* </a> */}
                </div>
              </div>
              <div className="sidebar-close-icon" onClick={handleNavCollapse}>
                <AiOutlineClose />
              </div>
            </div>
            <ul>
              {/* <Link to="/dashboard" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdDashboardCustomize style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Dashboard</span>
                </li>
              </Link> */}

              <Link to="/admin_banner" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Home Banner </span>
                </li>
              </Link>

              <Link to="/admin_category" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdCategory style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Category </span>
                </li>
              </Link>

              <Link to="/admin_events" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdEvent style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Tournament </span>
                </li>
              </Link>

              <Link to="/registration-list" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <FaRegistered style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">
                    Registered List ( {Addregister?.length} )
                  </span>
                </li>
              </Link>

              <Link to="/general_enquiry" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdOutlineSupportAgent style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">
                    General Enquiries ( {Addgeneral?.length} )
                  </span>
                </li>
              </Link>

              <Link to="">
                <li
                  className={`a-ele ${acc3 ? "active-0" : "null"}`}
                  onClick={() => {
                    setResult(!Result);
                  }}
                >
                  <span>
                    <BsGraphUpArrow style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Results </span>{" "}
                  {Result ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>
              <Link to="">
                {Result ? (
                  <>
                    <div className="webmanagement">
                      {/* <Link to="/admin_singleresults">
                        <li
                          className={`a-ele ${acc ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(true);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                          }}
                        >
                          <span>
                          <GiPodiumWinner
                      style={{ fontSize: "20px" }}
                    />
                          </span>
                          <span className="ms-2">Single Result</span>
                        </li>
                      </Link> */}
                      <Link to="/admin_doubleresults">
                        <li
                          className={`a-ele ${acc1 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(true);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                          }}
                        >
                          <span>
                            {" "}
                            <MdEmojiEvents style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Results</span>{" "}
                        </li>
                      </Link>

                      <Link to="/admin_achivers">
                        <li
                          className={`a-ele ${acc2 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(true);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                          }}
                        >
                          <span>
                            {" "}
                            <HiTrophy style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Achievers</span>{" "}
                        </li>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>

              <Link to="/admin_gallery" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <GrGallery style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Gallery</span>
                </li>
              </Link>

              <Link to="">
                <li
                  className={`a-ele ${acc3 ? "active-0" : "null"}`}
                  onClick={() => {
                    setHome(!Home);
                  }}
                >
                  <span>
                    <BsPersonFill style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>About us </span>{" "}
                  {Home ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>
              <Link to="">
                {Home ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/admin_overview">
                        <li
                          className={`a-ele ${acc ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(true);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                          }}
                        >
                          <span>
                            <GrOverview style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Overview</span>
                        </li>
                      </Link>
                      <Link to="/admin_keyhighlight">
                        <li
                          className={`a-ele ${acc1 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(true);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                          }}
                        >
                          <span>
                            {" "}
                            <SiTransmission style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Key Insights </span>{" "}
                        </li>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>

              <Link to="/admin_partners" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdGroups2 style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Partners</span>
                </li>
              </Link>

              <Link to="/admin_testimonial" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <LuAlignHorizontalJustifyStart
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Testimonials </span>
                </li>
              </Link>

              <Link to="/user_Review" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdOutlineReviews style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Review ( {AddFeedback?.length} )</span>
                </li>
              </Link>

              <Link to="/admin_contactus" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdContactPhone style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Contact Us</span>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Side;
