import React, { useState, useEffect } from "react";
import "../Styles/results.css";
import { Container, Button, Table } from "react-bootstrap";
import axios from "axios";
import Aos from "aos";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { PiArrowUp } from "react-icons/pi";

const Results = () => {
  useEffect(() => {
    Aos.init();
  });


    // Scrool window icon 

    const [visible, setVisible] = useState(false) 
  
    const toggleVisible = () => { 
      const scrolled = document.documentElement.scrollTop; 
      if (scrolled > 300){ 
        setVisible(true) 
      }  
      else if (scrolled <= 300){ 
        setVisible(false) 
      } 
    }; 
    
    const scrollToTop = () =>{ 
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
      }); 
    }; 
    
    window.addEventListener('scroll', toggleVisible);

  // ===============DOUBLE RESULT==================//
  //integrating get  method
  const [AddDounbleResult, setAddDounbleResult] = useState([]);
  console.log("AddDounbleResult..", AddDounbleResult);
  
  const getAddDounbleResult = async () => {
    try {
      let res = await axios.get("https://shuttlesmash.shop/api/admin/getresults");
      if (res.status === 200) {
        setAddDounbleResult(res.data.getresults);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddDounbleResult();
  }, []);

  // ===============ACHIVERS==================//
  //integrating get  method
  const [AddAchivers, setAddAchivers] = useState([]);
  const getAddAchivers = async () => {
    try {
      let res = await axios.get("https://shuttlesmash.shop/api/admin/getachivers");
      if (res.status === 200) {
        setAddAchivers(res.data.getachivers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddAchivers();
  }, []);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5000;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddDounbleResult.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddDounbleResult.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  return (
    <div>
      <div className="position-relative">
        <img src="../Assets/resultbg1.jpg" alt="" className="bg-img R-result-bg" />
        {/* <div>
          <h2 className="btitle R-btitle">RESULTS</h2>
        </div> */}
        <div className="fixed-icon" style={{border:"none",backgroundColor:"unset !important"}}>
        <div>
          <a
            href="https://wa.link/66qhiu"
            target="_new"
            style={{ color: "unset", textDecoration: "none" }}
          >
            {" "}
            <img src="../Assets/whatsapp-i.webp" style={{width:"50px",height:"50px"}}/>
            <span className="tooltip-text">Let's chat</span>
          </a>
        </div>
      </div>

          {/* scrooling up icon  */}
          <div>
        <div className="scrool-up-icon"  style={{display: visible ? 'inline' : 'none'}} onClick={scrollToTop}>
          <div className="scrool">
            <PiArrowUp style={{ fontSize: '20px' }} />
          </div>
        </div>
    
    </div>

      </div>

      <Container>
        <div
          className="row mt-3 mb-4"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="3000"
        >
          <div>
            <h2 className="headdings" style={{fontSize:"30px"}}>Previous Tournaments Results</h2>
          </div>
          <div className="overflow-y-auto">
            <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>SL.NO</th>
                  <th>Tournament Name</th>
                  <th>Date</th>
                  <th>Category</th>
                  <th>First Player Name</th>
                  <th>Second Player Name</th>
                  <th>Position</th>
                </tr>
              </thead>
              <tbody>
                {AddDounbleResult?.map((val, i) => {
                  return (
                    <tr>
                      <td>{i + 1 + firstIndex}</td>
                      <td>{val.TournamentName}</td>
                      <td>{val.TournamentDate}</td>
                      <td>{val.ResultCategory}</td>
                      <td>{val.FirstPlayername}</td>
                      <td>{val.SecondPlayername}</td>
                      <td>{val.Position}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="row mt-3 mb-4 d-flex justify-content-center">
          <div>
            <h2 className="headdings" style={{fontSize:"30px"}}>Our Achivers</h2>
          </div>
          <div className="mb-2 ">
            <a
              href="/gallery"
              style={{ color: "unset", textDecoration: "none" }}
            >
              <span className="viewmoreevnt" style={{ float: "right" }}>
                View More{" "}
                <span>
                  <FaArrowAltCircleRight
                    className="shake"
                    style={{ fontSize: "20px" }}
                  />
                </span>
              </span>
            </a>
          </div>
          <div className="row">
            {AddAchivers?.map((val, i) => {
              return (
                <div className="col-md-4">
                  <div className="mt-3">
                    <img
                      src={`https://shuttlesmash.shop/Achivers/${val?.AchiversImage}`}
                      alt=""
                      className="achivers-img"
                    />
                  </div>
                  <div className="achivers-name">{val.AchiversText}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Results;
