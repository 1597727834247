import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import * as XLSX from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

const RegistrationList = () => {
  // expot excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      Addregister.map((item, index) => ({
        "SL.NO": index + 1,
        "Tournament Name": item?.eventsId?.EventName,
        "Tournament Date": item?.eventsId?.EventStartdate,
        Category: item.Category?.map(
          (item1) => item1?.categoryId?.CategoryName
        ).join(", "),
        "Player's Name": Object.values(item.playerNames || {}).join(", "),
        "Player Email": item.PlayerEmail,
        "Player Contact": item.PlayerPhoneNo,
        "Total Amount": item.TotalAmount,
        "Payment Status": item.status === "Received" ? "Pending" : item.status,
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "RegistrationList");
    XLSX.writeFile(wb, "RegistrationList.xlsx");
  };

  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [Data, setData] = useState("");

  // DATE & MONTH FORMATE
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { day: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [show10, setShow10] = useState();
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState();
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  //integrating get  method
  const [Addregister, setAddregister] = useState([]);
  const getAddregister = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/user/getregistration"
      );
      if (res.status === 200) {
        setAddregister(res.data.getregistration);
        setNoChangeData1(res.data.getregistration)
      }
    } catch (error) {
      console.log(error);
    }
  };

  

  //Delete
  const Deleteregister = async () => {
    try {
      const config = {
        url: "user/Deleteregistration/" + Data,
        method: "delete",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddregister();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    getAddregister();
  }, []);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = Addregister.slice(firstIndex, lastIndex);
  const npages = Math.ceil(Addregister.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }


  // ===============PAYMENT DETAILS================//
  const [details, setdetails] = useState({});
  const [selectedPlayerId, setSelectedPlayerId] = useState({});
  useEffect(() => {
    if (selectedPlayerId) {
      getAddPayment(selectedPlayerId); // Fetch payment details for the selected player
    }
  }, [selectedPlayerId]);

  const [AccountHolderName, setAccountHolderName] = useState("");
  const [TransactionID, setTransactionID] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [PaidAmount, setPaidAmount] = useState("");
  const [Remarks, setRemarks] = useState("");

  const AddPaymentsDetial = async () => {
    try {
      if (!AccountHolderName) {
        return alert("Please Enter Account Holder Name");
      }
      if (!TransactionID) {
        return alert("Please Enter Transaction ID");
      }
      if (!AccountNumber) {
        return alert("Please Enter Phone Number");
      }
      if (!PaidAmount) {
        return alert("Please Enter Total Paid Amount");
      }
      if (!Remarks) {
        return alert("Please Enter Remarks");
      }
      const config = {
        url: "admin/payment",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: details?._id,
          AccountHolderName,
          TransactionID,
          AccountNumber: Number(AccountNumber),
          PaidAmount: Number(PaidAmount),
          Remarks,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        PaymentStatus();
        sendmail();
        getAddPayment();
        getAddregister();
        handleClose10();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const playerNamesArray = details?.playerNames
    ? Object.values(details.playerNames)
    : [];
  const sendmail = async () => {
    try {
      const config = {
        url: "user/paymentstatusmail",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          PlayerEmail: details?.PlayerEmail,
          playerNames: playerNamesArray,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        getAddregister();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const PaymentStatus = async () => {
    try {
      const config = {
        url: "user/paymentstatus",
        method: "put",
        baseURL: "https://shuttlesmash.shop/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          userId: details,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        // alert(res.data.success);
        getAddregister();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  //integrating get  method
  const [AddPayment, setAddPayment] = useState([]);

  const getAddPayment = async (playerId) => {
    try {
      let res = await axios.get("https://shuttlesmash.shop/api/admin/getpayment");
      if (res.status === 200) {
        setAddPayment(res.data.getpayment);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //integrating Update/edit  method
  const [newData, setNewdata] = useState({});
  const [Data3, setData3] = useState({});
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData3(item);
    setNewdata(item); // Set the item data here to reflect in the modal
    setAccountHolderName(item?.AccountHolderName || "");
    setTransactionID(item?.TransactionID || "");
    setAccountNumber(item?.AccountNumber || "");
    setPaidAmount(item?.PaidAmount || "");
    setRemarks(item?.Remarks || "");
  };

  const Editpayment = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/editpayment",
        method: "put",
        baseURL: "https://shuttlesmash.shop/api/",
        headers: { "content-type": "application/json" },
        data: {
          // id: Data3?._id, // Ensure the correct id is sent for update
          userId: Data3?._id,
          AccountHolderName,
          TransactionID,
          AccountNumber,
          PaidAmount,
          Remarks,
        },
      };

  
      const res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Updated");
        handleClose5();
        handleClose11();
        getAddPayment();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  
  useEffect(() => {
    getAddPayment();
  }, []);

  // ================TOTAL EARNINGS========================//

  // ==============DATE FILTER======================//

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const filterData = () => {
    if (!startDate) return alert("Please select a 'from' date");
    if (!endDate) return alert("Please select a 'to' date");

    // Convert input dates to moment objects
    const startDateObj = moment(startDate, "YYYY-MM-DD");
    const endDateObj = moment(endDate, "YYYY-MM-DD");

    // Ensure the end date is not before the start date
    if (endDateObj.isBefore(startDateObj)) {
      return alert("End date cannot be before the start date");
    }

    // Filter Addregister array based on the date range
    const filteredData = Addregister.filter((item) => {
      const itemDate = moment(item?.RegisteredDate, "YYYY-MM-DD");
      // Check if itemDate falls between startDate and endDate
      return (
        itemDate.isValid() &&
        itemDate.isSameOrAfter(startDateObj) &&
        itemDate.isSameOrBefore(endDateObj)
      );
    });
    console.log("Filtered Data: ", filteredData);
    // Update the state with the filtered data
    if (filteredData.length > 0) {
      setAddregister(filteredData);
    } else {
      alert("No records found within the selected date range");
      setAddregister([]); // Optionally, clear the data if no records found
    }
  };

  function clearbutton() {
    setendDate("");
    setstartDate("");
    getAddregister();
  }

  let subtotal = 0;
  if (Addregister?.length !== 0) {
    for (let i = 0; i < Addregister.length; i++) {
      subtotal += Addregister[i]?.TotalAmount;
    }
  }

console.log("records",records);
console.log("Addregister", Addregister);
const [searchH1, setSearchH1] = useState("");
const [nochangedata1, setNoChangeData1] = useState([]);

const searchItem = (e) => {
  const searchTerm1 = e.target.value.toLowerCase().trim();
  setSearchH1(searchTerm1);

  if (searchTerm1 !== "") {
    const filteredData1 = nochangedata1.filter((item) =>
      deepSearch(item, searchTerm1)
    );
    setAddregister(filteredData1);
  } else {
    setAddregister(nochangedata1);
  }
};

const deepSearch = (obj, searchTerm) => {
  if (typeof obj === "string" || typeof obj === "number") {
    return String(obj).toLowerCase().includes(searchTerm);
  }

  if (Array.isArray(obj)) {
    return obj.some((element) => deepSearch(element, searchTerm));
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.values(obj).some((value) => deepSearch(value, searchTerm));
  }

  return false;
};
 

  return (
    <div>
      <div className="d-flex gap-3 mb-2">
         <div className="col-lg-4 d-flex justify-content-center">
          <div class="input-group ">
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={searchItem}
              value={searchH1}
            />
          </div>
        </div> 
        <div>
          <h6 className="mt-2">Total Received Amount: {subtotal}</h6>
        </div>
      </div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c">Registration List</h2>
          <div className="date-filter">
            <label>From Date:</label> &nbsp;
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
            &nbsp;&nbsp;
            <label>To Date: </label> &nbsp;
            <Form.Control
              type="date"
              aria-describedby="basic-addon1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
            <button className="btn btn-primary" onClick={filterData}>
              Submit
            </button>{" "}
            &nbsp;
            <button className="btn btn-danger" onClick={clearbutton}>
              Clear
            </button>
          </div>
          <button className="admin-add-btn" onClick={exportToExcel}>
            Export Excel
          </button>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>SL. NO</th>
                <th>Registered Date</th>
                <th>Registered Time</th>
                <th>Tournament Name</th>
                <th>Tournament Date</th>
                <th style={{ width: "5rem" }}>Category Name</th>
                <th style={{ width: "5rem" }}>Player's Name</th>
                <th>Player Email ID</th>
                <th>Player Contact Number</th>
                <th>Total Amount</th>
                <th>Mention Payment Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {records?.map((item, i) => {
                // if (
                //   SearchItem === "" ||
                //   Object.values(item).some((value) =>
                //     String(value)
                //       .toLowerCase()
                //       .includes(SearchItem.toLowerCase())
                //   )
                // )
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: "20px" }}>{i + 1 + firstIndex}</td>
                      <td style={{ paddingTop: "20px" }}>
                        {moment(item.RegisteredDate).format("MM/DD/YYYY")}
                      </td>

                      <td style={{ paddingTop: "20px" }}>
                        {item.RegisteredTime}
                      </td>

                      <td style={{ paddingTop: "20px" }}>
                        {item?.eventsId?.EventName}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {item?.eventsId?.EventStartdate}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <div style={{ width: "150px" , textAlign:"left"}}>
                          {item.Category?.map((item1, index) => {
                            return (
                              <p>
                                <span>{index + 1}</span>.{" "}
                                {item1?.categoryId?.CategoryName}
                              </p>
                            );
                          })}
                        </div>
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <div style={{ width: "150px" }}>
                          {item.playerNames
                            ? Object.entries(item.playerNames).map(
                                ([key, name], index) => {
                                  const [categoryIndex, playerIndex] = key
                                    .split("_")
                                    .map(Number);
                                  const playerNumber = playerIndex + 1;
                                  return (
                                    <div
                                      key={index}
                                      style={{ textAlign: "left" }}
                                    >
                                      <div className="d-flex gap-2">
                                        {playerNumber}.{" "}
                                        <p style={{ marginBottom: "15px" }}>
                                          {name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : []}
                        </div>
                      </td>
                      <td style={{ paddingTop: "20px" }}>{item.PlayerEmail}</td>
                      <td style={{ paddingTop: "20px" }}>
                        {item.PlayerPhoneNo}
                      </td>
                      <td style={{ paddingTop: "20px" }}>{item.TotalAmount}</td>
                      <td style={{ paddingTop: "20px", width: "200px" }}>
                      
                        <div className="">
                          {item.status === "Pending" ? (
                            <div className="mb-2">
                              <div>
                                <Button
                                  onClick={() => {
                                    setdetails(item);
                                    handleShow10();
                                  }}
                                >
                                  Add Details
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="mb-2">
                              <Button
                                onClick={() => {
                                  setSelectedPlayerId(item?._id);
                                  handleShow11();
                                }}
                              >
                                View
                              </Button>
                            </div>
                          )}
                        </div>
                      </td>

                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow4();
                                setData(item?._id);
                              }}
                            />{" "}
                          </div>
                        </div>
                      </td>
                     
                    </tr>
                  );
              })}
            </tbody>
          </Table>

          <div>
            <nav>
              <ul className="pagination">
                <li className="not-allow">
                  <span>
                    <li className="next-prev">
                      <a
                        onClick={() => {
                          prevpage();
                        }}
                      >
                        &lt;
                      </a>{" "}
                    </li>
                  </span>
                </li>
                {numbers?.map((n, i) => {
                  return (
                    <li className="active-next" key={i}>
                      <a
                        href="#"
                        className="inactive"
                        onClick={() => changePage(n)}
                      >
                        {n}
                      </a>
                    </li>
                  );
                })}

                <li className="not-allow">
                  <span>
                    <li
                      className="next-prev"
                      onClick={() => {
                        nextpage();
                      }}
                    >
                      &gt;{" "}
                    </li>
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* Delet modal  */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={Deleteregister}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/*PAYMENT ADD */}
      <Modal show={show10} onHide={handleClose10}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Account Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Account Holder Name</label>
              <input
                type="text"
                name=""
                className="vi_0"
                placeholder="Enter Account Holder Name"
                onChange={(e) => setAccountHolderName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Transaction Number</label>
              <input
                type="text"
                name=""
                className="vi_0"
                placeholder="Enter Transaction Number"
                onChange={(e) => setTransactionID(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Phone Number</label>
              <input
                type="number"
                name=""
                className="vi_0"
                placeholder="Enter Phone Number"
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Received Amount</label>
              <input
                type="number"
                name=""
                className="vi_0"
                placeholder="Enter Received Amount"
                onChange={(e) => setPaidAmount(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Remarks</label>
              <input
                type="text"
                name=""
                className="vi_0"
                placeholder="Enter Remarks"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#52f310", color: "#ffff" }}
            onClick={handleClose10}
          >
            Cancel
          </Button>
          <Button onClick={AddPaymentsDetial}>Add Payment</Button>
        </Modal.Footer>
      </Modal>

      {/*PAYMENT EDIT */}
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Edit Account Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Account Holder Name</label>
              <input
                type="text"
                name=""
                className="vi_0"
                placeholder={Data3?.AccountHolderName}
                value={AccountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Transaction Number</label>
              <input
                type="text"
                name=""
                className="vi_0"
                placeholder={Data3?.TransactionID}
                value={TransactionID}
                onChange={(e) => setTransactionID(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Phone Number</label>
              <input
                type="number"
                name=""
                className="vi_0"
                placeholder={Data3?.AccountNumber}
                value={AccountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Received Amount</label>
              <input
                type="number"
                name=""
                className="vi_0"
                placeholder={Data3?.PaidAmount}
                value={PaidAmount}
                onChange={(e) => setPaidAmount(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Remarks</label>
              <input
                type="text"
                name=""
                className="vi_0"
                placeholder={Data3?.Remarks}
                value={Remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#52f310", color: "#ffff" }}
            onClick={handleClose5}
          >
            Cancel
          </Button>
          <Button onClick={Editpayment}>Update Payment</Button>
        </Modal.Footer>
      </Modal>

      {/*PAYMENT VIEW */}
      <Modal
        show={show11}
        onHide={handleClose11}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          {AddPayment?.length > 0 ? (
            AddPayment.filter((items) => items?.userId == selectedPlayerId).map(
              (payment, index) => {
                return (
                  <div key={index}>
                    <p>
                      {" "}
                      <b>Account Holder Name: </b>{" "}
                      <span>{payment.AccountHolderName}</span>
                    </p>
                    <p>
                      {" "}
                      <b>Transaction ID: </b>{" "}
                      <span>{payment.TransactionID}</span>
                    </p>
                    <p>
                      {" "}
                      <b>Phone Number: </b>{" "}
                      <span>{payment.AccountNumber}</span>
                    </p>
                    <p>
                      {" "}
                      <b>Received Amount: </b> <span>{payment.PaidAmount}</span>
                    </p>
                    <p>
                      {" "}
                      <b>Remarks: </b>
                      <span>{payment.Remarks}</span>
                    </p>

                    <div>
                    <Button
                                className="mb-2"
                                onClick={() => {
                                  handleShow5();
                                  setData3(payment);
                                }}                              >
                                Edit
                              </Button>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div>Nw0eu9t0u9</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose11}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RegistrationList;
