import React, { useState, useEffect } from "react";
import "../Styles/events.css";
import { Container, Button, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from "html-react-parser";
import Aos from "aos";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { PiArrowUp } from "react-icons/pi";
import { FaDownload } from "react-icons/fa";
import { saveAs } from 'file-saver';

const Events = () => {
  useEffect(() => {
    Aos.init();
  });

  // Scrool window icon

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  // Email formate Validation
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (value) => {
    // Regex pattern for validating an email address
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  // Phone number validation
  const [PlayerPhone, setPlayerPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (number) => {
    // Basic validation: Ensure it's a 10-digit number
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  //TIME AM PM FORMATE
  function convertTo12HourFormat(timeString) {
    // Split the time string into hours and minutes
    let [hours, minutes] = timeString.split(":");

    // Convert the string hours to a number
    hours = parseInt(hours);

    // Determine AM or PM suffix
    const suffix = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // Convert '0' to '12'

    // Return the formatted time string
    return `${hours}:${minutes} ${suffix}`;
  }

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPlayerPhone(value);
    setIsPhoneValid(validatePhoneNumber(value));
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    if (!FirstPlayerName) {
      return alert("Please Choose Player number");
    }
    if (!FirstPlayerEmail) {
      return alert("Please add email");
    }
    if (!FirstPlayerPhone) {
      return alert("Please add contact number");
    }
    // Validation for Second Player (if applicable)
    if (SecondPlayerName && !SecondPlayerEmail) {
      return alert("Please provide the email for the second player");
    }
    if (SecondPlayerName && !SecondPlayerPhone) {
      return alert("Please provide the phone number for the second player");
    }
    setShow1(true);
  };

  const [Default, setDefault] = useState(true);
  const [Single, setSingle] = useState(false);
  const [Double, setDouble] = useState(false);

  const [playerType, setPlayerType] = useState("default");
  const [itemeb, setitemeb] = useState();
  // post method form
  const formdata = new FormData();
  const [Data, setData] = useState("");

  const [EventName, setEventName] = useState("");
  const [EventDate, setEventDate] = useState("");
  const [Category, setCategory] = useState("");
  const [SubCategory, setSubCategory] = useState("");

  const [FirstPlayerName, setFirstPlayerName] = useState("");
  const [FirstPlayerEmail, setFirstPlayerEmail] = useState("");
  const [FirstPlayerPhone, setFirstPlayerPhone] = useState("");

  const [SecondPlayerName, setSecondPlayerName] = useState("");
  const [SecondPlayerEmail, setSecondPlayerEmail] = useState("");
  const [SecondPlayerPhone, setSecondPlayerPhone] = useState("");
  const [TotalAmount, setTotalAmount] = useState("");

  const AddRegisterquery = async () => {
    try {
      const config = {
        url: "/user/registration",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          EventName: itemeb?.EventName,
          EventDate: itemeb?.EventStartdate,
          TotalAmount: itemeb?.Price,
          Category: itemeb?.EventCategory,
          SubCategory: itemeb?.EventSubCategory,
          FirstPlayerName: FirstPlayerName,
          FirstPlayerEmail: FirstPlayerEmail,
          FirstPlayerPhone: FirstPlayerPhone,
          SecondPlayerName: SecondPlayerName,
          SecondPlayerEmail: SecondPlayerEmail,
          SecondPlayerPhone: SecondPlayerPhone,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        sendmail();
        getAddregister();
        handleClose();
        handleClose1();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sendmail = async () => {
    try {
      const config = {
        url: "/user/sendmail",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          playerType: playerType,
          EventName: itemeb?.EventName,
          EventDate: itemeb?.EventStartdate,
          TotalAmount: itemeb?.Price,
          Category: Category,
          SubCategory: SubCategory,
          FirstPlayerName: FirstPlayerName,
          FirstPlayerEmail: FirstPlayerEmail,
          FirstPlayerPhone: FirstPlayerPhone,
          SecondPlayerName: SecondPlayerName,
          SecondPlayerEmail: SecondPlayerEmail,
          SecondPlayerPhone: SecondPlayerPhone,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //integrating get  method
  const [Addregister, setAddregister] = useState([]);
  const getAddregister = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/user/getregistration"
      );
      if (res.status === 200) {
        setAddregister(res.data.getregistration);
        setNoChangeData(res.data.getregistration);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddregister();
  }, []);
  console.log(Addregister);

  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = Addregister.slice(firstIndex, lastIndex);
  const npages = Math.ceil(Addregister.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }
  const [AddEvents, setAddEvents] = useState([]);
  const getAddEvents = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getevents"
      );
      if (res.status === 200) {
        setAddEvents(res.data.getevents);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddEvents();
  }, []);

  // Search filter
  const [nochangedata, setNoChangeData] = useState([]);
  const [searchH, setSearchH] = useState("");

  const handleFilterH = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchH(searchTerm);
    if (searchTerm !== "") {
      const filteredData = nochangedata.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm)
        )
      );
      setAddregister(filteredData);
    } else {
      setAddregister(nochangedata);
    }
  };

  //integrating get  method
  const [newData, setNewdata] = useState({});

  const [AddCategory, setAddCategory] = useState([]);
  const getAddCategory = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getcategory"
      );
      if (res.status === 200) {
        setAddCategory(res.data.getcategory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddCategory();
  }, []);

  const [AddSubcategory, setAddSubcategory] = useState([]);
  const getAddSubcategory = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getsubcategory"
      );
      if (res.status === 200) {
        setAddSubcategory(res.data.getsubcategory);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddSubcategory();
  }, []);

  //integrating get  method EVENTS BANNER
  const [AddEventbanner, setAddEventbanner] = useState([]);
  const getAddEventbanner = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/geteventbanner"
      );
      if (res.status === 200) {
        setAddEventbanner(res.data.geteventbanner);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddEventbanner();
  }, []);

  //integrating get  method TERMS & CONDITION
  const [AddTerms, setAddTerms] = useState([]);
  const getAddTerms = async () => {
    try {
      let res = await axios.get("https://shuttlesmash.shop/api/admin/getterms");
      if (res.status === 200) {
        setAddTerms(res.data.getterms);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddTerms();
  }, []);

  // =============== BROCHURE DOWNLOAD================== //

  //integrating get  method EVENTS BANNER
  const [AddBrochure, setAddBrochure] = useState([]);
  const getAddBrochure = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getbrochures"
      );
      if (res.status === 200) {
        setAddBrochure(res.data.getbrochures);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddBrochure();
  }, []);

  // Selected Checkboxs
  // const [selectedCategories, setSelectedCategories] = useState([]);
  // const handleCheckboxChange = (value, checked) => {
  //   setSelectedCategories((prev) => {
  //     if (checked) {
  //       return [...prev, value];
  //     } else {
  //       return prev.filter((item) => item !== value);
  //     }
  //   });
  // };

  const [selectedCategories, setSelectedCategories] = useState({
    sum: 0,
    items: [],
  });

  // const handleCheckboxChange = (value, checked,item) => {
  //   setSelectedCategories((prev) => {
  //     if (checked) {
  //       const newValue = (prev[0] || 0) + Number(value);
  //       return [newValue];
  //     } else {
  //       const newValue = (prev[0] || 0) - Number(value);
  //       return [newValue];
  //     }
  //   });
  // };

  const handleCheckboxChange = (value, checked, item) => {
    setSelectedCategories((prev) => {
      const newSum =
        (prev.sum || 0) + (checked ? Number(value) : -Number(value));
      const newItems = checked
        ? [...prev.items, item]
        : prev.items.filter((i) => i !== item);

      return { sum: newSum, items: newItems };
    });
  };

  let Totalnumberfoplayer = selectedCategories.sum;
  let TextfieldArray = Array(Totalnumberfoplayer).fill(Totalnumberfoplayer);

  console.log("TextfieldArray", TextfieldArray.length);
  console.log("selectedCategoriesssss", selectedCategories);
  const [playerNames, setPlayerNames] = useState({});

  const handlePlayerNameChange = (index, name) => {
    setPlayerNames((prev) => ({ ...prev, [index]: name }));
  };
  console.log("playerNames", playerNames);
  const [PlayerEmail, setPlayerEmail] = useState("");
  const [PlayerPhoneNo, setPlayerPhoneNo] = useState("");
  const registerpalyer = async () => {
    try {
      const config = {
        url: "/user/registration",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          playerNames: playerNames,
          PlayerEmail: PlayerEmail,
          PlayerPhoneNo: PlayerPhoneNo,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate("/registration", { state: { item: item } });
  };

  return (
    <div>
      <div className="position-relative">
        <img src="../Assets/Capture.PNG" alt="" className="bg-img R-event-bg" />
        <div>{/* <h2 className="title6">EVENTS</h2> */}</div>
        <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/66qhiu"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
              />
              <span className="tooltip-text">Let's chat</span>
            </a>
          </div>
        </div>

        {/* scrooling up icon  */}
        <div>
          <div
            className="scrool-up-icon"
            style={{ display: visible ? "inline" : "none" }}
            onClick={scrollToTop}
          >
            <div className="scrool">
              <PiArrowUp style={{ fontSize: "20px" }} />
            </div>
          </div>
        </div>
      </div>

      {/* ==============================EVENT BANNER ============================= */}
      <div>
        {AddEventbanner?.map((val, i) => {
          return (
            <div className="mt-2 mb-3">
              <div>
                <h2 className="title111">{val.EventbannerTitle}</h2>
              </div>

              <img
                src={`https://shuttlesmash.shop/Events/${val?.EventbannerImage}`}
                alt=""
                className="upcomingbanner-img"
              />
            </div>
          );
        })}
      </div>

      <div className="event-content-bg mt-2">
        <Container>
          {AddEvents?.filter((ele) => ele.isBlock === false)?.map((item, i) => {
            return (
              <div className="row mt-2 mb-3">
                <div
                  className="col-md-3"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <div>
                    <img
                      src={`https://shuttlesmash.shop/Events/${item?.EventImage}`}
                      alt=""
                      className="eventimg"
                    />
                  </div>
                </div>

                <div className="col-md-9">
                  <div
                    className="events-details "
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    {/* <div className="event-name">
                    <b>{item.EventName}</b>
                  </div> */}

                    <div className="details mt-2">
                      <div className="event-detl">
                        <b>Tournament Name</b>
                        <span>: {item.EventName}</span>
                      </div>

                      <div className="event-detl">
                        <b>Venue</b>
                        <span>: {item.EventLocation}</span>
                      </div>
                      <div className="event-detl">
                        <b>Date</b>
                        <span>: {item.EventStartdate}</span>
                      </div>
                      {/* <div className="event-detl">
                      <b>Event End Date</b>
                      <span>: {item.EventEnddate}</span>
                    </div> */}

                      <div className="event-detl">
                        <b>Time</b>
                        <span>: {convertTo12HourFormat(item.Time)}</span>
                      </div>

                      <div className="event-detl">
                        <b>Contact Number</b>
                        <span>: {item.EventExhibits}</span>
                      </div>

                      <div className="event-detl">
                        <b>Status</b>
                        <span>: {item.Status}</span>
                      </div>
                    </div>

                    <div className="d-flex gap-3 mt-2">
                      {/* <Button variant="" className="registr-btn" onClick={() => handleClick(item)}>
                          Register 
                        </Button> */}

                      {/* Conditionally render Register button */}
                      {item.Status === "Upcoming" && (
                        <Button
                          variant=""
                          className="registr-btn"
                          onClick={() => handleClick(item)}
                        >
                          Register
                        </Button>
                      )}

                      <div>
                        <a
                          href="http://chat.whatsapp.com/JSgotD6mlBZ6XXbMdBWxDW"
                          style={{ color: "unset", textDecoration: "none" }}
                        >
                          <Button variant="" className="join-whatsp">
                            <IoLogoWhatsapp style={{ fontSize: "20px" }} /> Join
                            WhatsApp group
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Container>
      </div>
      {/* DOWNLOAD BROCHURE  */}
      <Container data-aos="fade-up" data-aos-duration="3000">
        <div>
          {AddBrochure?.map((val, i) => {
            return (
              <div className="brochure-section text-center p-4 mt-4 position-relative">
                {/* <img
                  src="../Assets/badmintonleftsmall.png" // Replace with your actual image path
                  alt="Decorative cross"
                  className="cross-img1"
                /> */}
                <h4 className="mb-3">
                  "To know more about Tournament, please download the flyer."{" "}
                </h4>
                <Button
  variant=""
  className="brochure-download-btn px-4 py-2"
  onClick={async () => {
    const fileUrl = `https://shuttlesmash.shop/Events/${val?.Brochure}`;
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      saveAs(blob, val?.Brochure); // This triggers the download directly
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  }}
>
  <FaDownload style={{ margin: "0px 4px" }} />
  Download Flyer
</Button>
                {/* <img
                  src="../Assets/badmintonrightsmall.png" // Replace with your actual image path
                  alt="Decorative cross"
                  className="cross-img"
                /> */}
              </div>
            );
          })}
        </div>
      </Container>

      <Container data-aos="fade-up" data-aos-duration="3000">
        <div>
          {AddTerms?.map((val, i) => {
            return (
              <div className="mt-3 mb-3">
                {parse(`<div>${val.Eligibility}</div>`)}
              </div>
            );
          })}
        </div>
      </Container>

      {/*  Registration Modal  */}
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}></Modal.Title>
        </Modal.Header>
        <h4
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold",
            padding: "5px 10px",
            color: "#004aad",
          }}
        >
          {" "}
          Shuttle Smash Championship Registration
        </h4>

        <Modal.Body>
          <div className="row">
            <div className="row">
              <div className="do-sear mt-2">
                <label>Select Category</label>
                <div
                  style={{ padding: "8px", borderRadius: "5px" }}
                  className="form-control"
                >
                  {AddCategory?.map((item, i) => (
                    <div key={i}>
                      <input
                        type="checkbox"
                        id={`Category-${i}`}
                        value={item.TotalPlayers}
                        onChange={(e) => {
                          handleCheckboxChange(
                            e.target.value,
                            e.target.checked,
                            item
                          );
                        }}
                      />{" "}
                      <span htmlFor={`Category-${i}`}>{item.CategoryName}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {TextfieldArray?.map((item, i) => {
              return (
                <div key={i} className="do-sear mt-2">
                  <label>Player Name {i + 1}</label>
                  <input
                    type="text"
                    id={`Name${i}`}
                    name={`Name${i}`}
                    placeholder="Enter Your Name"
                    className="vi_0"
                    onChange={(e) => handlePlayerNameChange(i, e.target.value)}
                  />
                </div>
              );
            })}

            <div>
              {/* <div className="do-sear mt-2">
                <label>Player Name</label>
                <input
                  type="text"
                  id="Name"
                  name="Name"
                  placeholder="Enter Your Name"
                  className="vi_0"
                  onChange={(e) => setFirstPlayerName(e.target.value)}
                />
              </div> */}
              <div className="do-sear mt-2">
                <label>Player Email ID</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Your Email"
                  className="vi_0"
                  style={{ borderColor: isEmailValid ? "" : "red" }}
                  onChange={(e) => {
                    setPlayerEmail(e.target.value);
                    handleEmailChange(e);
                  }}
                />
                {!isEmailValid && (
                  <p style={{ color: "red" }}>
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              <div className="do-sear mt-2">
                <label>Contact Number</label>
                <input
                  type="number"
                  id="number"
                  name="number"
                  placeholder="+91 Enter Contact Number"
                  className="vi_0"
                  style={{ borderColor: isPhoneValid ? "" : "red" }}
                  onChange={(e) => {
                    setPlayerPhoneNo(e.target.value); // Updates state with the new email value
                    handlePhoneChange(e); // Validates the email
                  }}
                />
                {!isPhoneValid && (
                  <p style={{ color: "red" }}>
                    Please enter a valid 10-digit phone number.
                  </p>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              // onClick={() => {
              //   handleClose();
              //   handleShow1();
              // }}
              onClick={registerpalyer}
            >
              Proceed
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/*  Payment Modal  */}
      <Modal
        show={show1}
        onHide={handleClose1}
        style={{ zIndex: "99999" }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}> </Modal.Title>{" "}
          {AddEvents?.map((val, i) => {
            return (
              <div style={{ margin: "auto", textDecoration: "underline" }}>
                <div>
                  <h4>
                    <b>Pay Rs {val?.Price}</b>{" "}
                  </h4>
                </div>
              </div>
            );
          })}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="d-flex gap-2">
                <b style={{ fontSize: "18px", color: "green" }}>Note: </b>
                <h6>
                  After successful payment, please share the screenshot here.{" "}
                  <a
                    href="http://chat.whatsapp.com/JSgotD6mlBZ6XXbMdBWxDW"
                    style={{ fontSize: "14px" }}
                  >
                    http://chat.whatsapp.com/JSgotD6mlBZ6XXbMdBWxDW
                  </a>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 m-auto">
            <img src="../Assets/payment.jpg" alt="" style={{ width: "100%" }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose1}
            >
              Cancel
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={AddRegisterquery}
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Events;
