import React, { useState, useEffect } from "react";
import "../Styles/keyhighlight.css";
import { Container } from "react-bootstrap";
import axios from "axios";
import parse from "html-react-parser";
import { BsValentine } from "react-icons/bs";
import Aos from "aos";
import { PiArrowUp } from "react-icons/pi";

const KeyHighlights = () => {
  useEffect(() => {
    Aos.init();

  });

    // Scrool window icon 

    const [visible, setVisible] = useState(false) 
  
    const toggleVisible = () => { 
      const scrolled = document.documentElement.scrollTop; 
      if (scrolled > 300){ 
        setVisible(true) 
      }  
      else if (scrolled <= 300){ 
        setVisible(false) 
      } 
    }; 
    
    const scrollToTop = () =>{ 
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
      }); 
    }; 
    
    window.addEventListener('scroll', toggleVisible);


  //integrating get  method
  const [AddKeyhighlight, setAddKeyhighlight] = useState([]);
  const getAddKeyhighlight = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getkeyhighlight"
      );
      if (res.status === 200) {
        setAddKeyhighlight(res.data.getkeyhighlight);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddKeyhighlight();
  }, []);

  return (
    <>
    <div>
    <div className="position-relative">
      <img src="../Assets/keyhighlightbg2.jpg" alt="" className="bg-img R-overview-bg" />
      <div>
        {/* <h2 className="title R-overview-title">OVERVIEW</h2> */}
      </div>
      <div className="fixed-icon" style={{border:"none",backgroundColor:"unset !important"}}>
        <div>
          <a
            href="https://wa.link/66qhiu"
            target="_new"
            style={{ color: "unset", textDecoration: "none" }}
          >
            {" "}
            <img src="../Assets/whatsapp-i.webp" style={{width:"50px",height:"50px"}}/>
            <span className="tooltip-text">Let's chat</span>
          </a>
        </div>
      </div>

         {/* scrooling up icon  */}
         <div>
        <div className="scrool-up-icon"  style={{display: visible ? 'inline' : 'none'}} onClick={scrollToTop}>
          <div className="scrool">
            <PiArrowUp style={{ fontSize: '20px' }} />
          </div>
        </div>
    
    </div>

    </div>

    {/* <div className="">
      {AddOverview?.map((val, i) => {
        return (
          <div className="row mt-3 mb-3 R-overview-mb position-relative">
            <div
              className=" background-image-change"
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div className="image-back" >
                <img
                  src={`https://shuttlesmash.shop/WebManagement/${val?.OverviewImage}`}
                  alt=""
                  style={{ width: "100%" }}
                  
                />
              </div>
            </div>
            <div className="image-back-blur  " >
              <div
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                {parse(`<div>${val.OverviewDesc}</div>`)}{" "}
              </div>
              <div className="d-flex gap-3 align-items-center">
              <div className="text-center">
                <img
                  // src={`https://shuttlesmash.shop/WebManagement/${val?.OverviewImage}`}
                  src="/Assets/client2.png"
                  alt=""
                  style={{ width: "35%" ,height:"25%",borderRadius:"50%",border:"2px solid" }}
                /><br/>
                <b>Mr.Pavan Organiser</b>
              </div>
              <div className="text-center">
                <img
                  // src={`https://shuttlesmash.shop/WebManagement/${val?.OverviewImage}`}
                  src="/Assets/client1.png"
                  alt=""
                  style={{ width: "35%" ,height:"25%",borderRadius:"50%" ,border:"2px solid"}}
                /><br/>
                <b>Mrs. Geetha Organiser</b>
              </div>
              </div>
             
            </div>
           
          </div>
        );
      })}
    </div> */}
    <Container>
    <div className="">
      {AddKeyhighlight?.map((val, i) => {
        return (
          <div className="position-relative mt-3 mb-2 overflow-hidden">
            <div
              className="background-image-change"
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              {/* <div className="image-back" >
                <img
                  src={`https://shuttlesmash.shop/WebManagement/${val?.KeyhighlightImage}`}
                  alt=""
              
                  
                />
              </div> */}
            </div>
            <div className=" " >
              <div
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                {parse(`<div>${val.KeyhighlightDesc}</div>`)}{" "}
              </div>
            
              <div>
              <div className="d-flex align-items-center">
              {/* <div className="row"><h1> Meet the Organiser</h1></div> */}
              <div style={{display:"flex",gap:"20px",alignItems:"center"}}>
              {/* <div className="text-center">
                <img
                  // src={`https://shuttlesmash.shop/WebManagement/${val?.OverviewImage}`}
                  src="/Assets/client2crop.png"
                  alt=""
                  style={{ width: "44%" ,height:"44%", }}
                /><br/>
                <b>Mr.Pavan <br /></b>
              </div>
              <div className="">
                <img
                  // src={`https://shuttlesmash.shop/WebManagement/${val?.OverviewImage}`}
                  src="/Assets/client1crop.png"
                  alt=""
                  style={{ width: "40%" ,height:"40%" }}
                /><br/>
                <b className="text-center" style={{marginLeft:"33px"}}>Mrs. Geetha <br /></b>
              </div> */}
              </div>
              </div>
              </div>
             
            </div>
           
          </div>
        );
      })}
    </div>
    </Container>
  </div>
    
 </>
  );
};

export default KeyHighlights;
