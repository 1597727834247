import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from "html-react-parser";

const Testimonials = () => {
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // name validation
  const [isNameValid, setIsNameValid] = useState(true);

  const validateName = (name) => {
    // Regular expression to allow only letters, spaces, and hyphens
    const namePattern = /^[a-zA-Z\s-]+$/;
    return namePattern.test(name);
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setName(name);

    // Validate name
    if (validateName(name)) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  };

  // Location validation
const [isLocationValid, setIsLocationValid] = useState(true);

const validateLocation = (location) => {
  // Regular expression to allow only letters, spaces, commas, and hyphens
  const locationPattern = /^[a-zA-Z\s,-]+$/;
  return locationPattern.test(location);
};

const handleLocationChange = (e) => {
  const location = e.target.value;
  setDesignation(location);

  // Validate location
  if (validateLocation(location)) {
    setIsLocationValid(true);
  } else {
    setIsLocationValid(false);
  }
};
  // integrating post method
  const [Name, setName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Description, setDescription] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };


  const AddTestimonialdetails = async () => {
    try {
      if (!Name) {
        return alert("Please add Name");
      }

      if (!Designation) {
        return alert("Please add Designationy");
      }

      if (!Description) {
        return alert("Please add Description");
      }

      const config = {
        url: "admin/testimonial",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          Name: Name,
          Designation: Designation,
          Description: Description,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddTestimonial();
        handleClose();
        setName(" ");
        setDesignation(" ");
        setDescription(" ");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get  method
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonial = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/gettestimonial"
      );
      if (res.status === 200) {
        setAddTestimonial(res.data.gettestimonial);
        // setNoChangeData1(res.data.gettestimonial);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteTestimonial = async () => {
    try {
      const config = {
        url: "admin/Deletetestimonial/" + Data,
        method: "delete",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddTestimonial();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
    setName(item?.Name);
    setDesignation(item?.Designation);
    setDescription(item?.Description);
  };

  const EditTestimonial = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "admin/edittestimonial",
        method: "put",
        baseURL: "https://shuttlesmash.shop/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          id: Data1?._id,
          Name: Name,
          Designation: Designation,
          Description: Description,
        },
      };

      const res = await axios(config);
      if (res.status === 200) {
        alert("Successfully Updated");
        handleClose3();
        getAddTestimonial();
      }
    } catch (error) {
      console.error(error);
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  useEffect(() => {
    getAddTestimonial();
  }, []);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddTestimonial.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddTestimonial.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  // Search filter
  const [nochangedata1, setNoChangeData1] = useState([]);
  const [searchH1, setSearchH1] = useState("");

  const handleFilterH = (e) => {
    const searchTerm1 = e.target.value.toLowerCase();
    setSearchH1(searchTerm1);
    if (searchTerm1 !== "") {
      const filteredData1 = nochangedata1.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm1)
        )
      );
      setAddTestimonial(filteredData1);
    } else {
      setAddTestimonial(nochangedata1);
    }
  };

  return (
    <div className="customerhead p-2">
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
            onChange={handleFilterH}
          />
        </div>
      </div> */}

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="header-c ">Testimonials</h2>
        <button className="admin-add-btn" onClick={handleShow}>
          Add Testimonials
        </button>
      </div>

      <div className="mb-3">
        <Table responsive bordered style={{ width: "-webkit-fill-available" }}>
          <thead>
            <tr>
              <th>SL.NO</th>
              <th>Name</th>
              <th>Location</th>
              <th>Message</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {records?.map((item, i) => {
              return (
                <tr key={i}>
                  <td style={{ paddingTop: "20px" }}>{i + 1 + firstIndex}</td>
                  <td style={{ paddingTop: "20px" }}>{item.Name}</td>
                  <td style={{ paddingTop: "20px" }}>{item.Designation}</td>

                  <td style={{ paddingTop: "20px" }}>
                    {parse(`<div>${item.Description}</div>`)}
                  </td>

                  <td style={{ paddingTop: "20px" }}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <BiSolidEdit
                          className="text-success"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => handleShow3(item)}
                        />{" "}
                      </div>
                      <div>
                        <AiFillDelete
                          className="text-danger"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow4();
                            setData(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div>
          <nav>
            <ul className="pagination">
              <li className="not-allow">
                <span>
                  <li className="next-prev">
                    <a
                      onClick={() => {
                        prevpage();
                      }}
                    >
                      &lt;
                    </a>{" "}
                  </li>
                </span>
              </li>
              {numbers?.map((n, i) => {
                return (
                  <li className="active-next" key={i}>
                    <a
                      href="#"
                      className="inactive"
                      onClick={() => changePage(n)}
                    >
                      {n}
                    </a>
                  </li>
                );
              })}

              <li className="not-allow">
                <span>
                  <li
                    className="next-prev"
                    onClick={() => {
                      nextpage();
                    }}
                  >
                    &gt;{" "}
                  </li>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Add testimonail */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Add Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Name</label>

              <input
                type="text"
                className="vi_0"
                placeholder="Enter Name"
                onChange={handleNameChange}
              />
              {!isNameValid && (
                <p style={{ color: "red" }}>
                  Please enter a valid name (only letters ).
                </p>
              )}
            </div>
          </div>

          <div className="row">
          <div className="do-sear mt-2">
              <label>Add Location</label>

              <input
                type="text"
                className="vi_0"
                placeholder="Enter Location"
                onChange={handleLocationChange}
              />
              {!isLocationValid && (
                <p style={{ color: "red" }}>
                  Please enter a valid Location name (only letters ).
                </p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Message</label>
              <CKEditor editor={ClassicEditor} onChange={handleChange} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={AddTestimonialdetails}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit testimonail  */}

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>
            Edit Testimonials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Name</label>
              <input
                type="text"
                className="vi_0"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Location</label>
              <input
                type="text"
                className="vi_0"
                value={Designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Edit Message</label>
              <CKEditor
                editor={ClassicEditor}
                data={Description}
                onChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="modal-close-btn" onClick={handleClose3}>
            Close
          </Button>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={EditTestimonial}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delet signle  */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="fs-4" style={{ color: "red" }}>
                Are you sure?
                <br /> you want to delete this data?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="modal-close-btn" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={DeleteTestimonial}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Testimonials;
