import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment/moment";

const UserReview = () => {
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // integrating post method
  const [UserName, setUserName] = useState("");
  const [UserPhoneNumber, setUserPhoneNumber] = useState("");
  const [UserLocation, setUserLocation] = useState("");
  const [FeedbackMessage, setFeedbackMessage] = useState("");
  const [FeedbackStatus, setFeedbackStatus] = useState("");
  const [FeedbackDate, setFeedbackDate] = useState("");

       // Generate the current date in the format 17-August-2024
       const currentDate = new Date();
       ;

      
  const AddFeedbackdetaisl = async () => {
    try {
      const config = {
        url: "user/feedback",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          UserName: UserName,
          UserPhoneNumber: UserPhoneNumber,
          UserLocation: UserLocation,
          FeedbackMessage : FeedbackMessage,
          FeedbackStatus: FeedbackStatus,
          FeedbackDate: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddFeedback();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get  method
  const [AddFeedback, setAddFeedback] = useState([]);
  const getAddFeedback = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/user/getfeedback"
      );
      if (res.status === 200) {
        setAddFeedback(res.data.getfeedback);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteFeedback = async () => {
    try {
      const config = {
        url: "user/DeleteFeedback/" + Data,
        method: "delete",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddFeedback();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    getAddFeedback();
  }, []);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddFeedback.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddFeedback.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  // Search filter
  const [nochangedata1, setNoChangeData1] = useState([]);
  const [searchH1, setSearchH1] = useState("");

  const handleFilterH = (e) => {
    const searchTerm1 = e.target.value.toLowerCase();
    setSearchH1(searchTerm1);
    if (searchTerm1 !== "") {
      const filteredData1 = nochangedata1.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm1)
        )
      );
      setAddFeedback(filteredData1);
    } else {
      setAddFeedback(nochangedata1);
    }
  };

  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
            onChange={handleFilterH}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="header-c ">Review</h2>
      </div>

      <div className="mb-3">
        <Table responsive bordered style={{ width: "-webkit-fill-available" }}>
          <thead>
            <tr>
              <th>SL.NO</th>
              <th>Review Sent Date</th>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Location</th>
              <th>Message</th>
              <th>Display in Website</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {records?.map((item, i) => {
              return (
                <tr key={i}>
                  <td style={{ paddingTop: "20px" }}>{i + 1 + firstIndex}</td>
                  <td style={{ paddingTop: "20px" }}>
                        {moment(item.FeedbackDate).format("MM/DD/YYYY")}
                      </td>

                  <td style={{ paddingTop: "20px" }}>{item.UserName}</td>
                  <td style={{ paddingTop: "20px" }}>{item.UserPhoneNumber}</td>
                  <td style={{ paddingTop: "20px" }}>{item.UserLocation}</td>
                  <td style={{ paddingTop: "20px" }}>
                    {parse(`<div>${item.FeedbackMessage}</div>`)}
                  </td>
                  <td style={{ paddingTop: "20px" }}>{item.FeedbackStatus}</td>

                  <td style={{ paddingTop: "20px" }}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                     
                      <div>
                        <AiFillDelete
                          className="text-danger"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow4();
                            setData(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div>
          <nav>
            <ul className="pagination">
              <li className="not-allow">
                <span>
                  <li className="next-prev">
                    <a
                      onClick={() => {
                        prevpage();
                      }}
                    >
                      &lt;
                    </a>{" "}
                  </li>
                </span>
              </li>
              {numbers?.map((n, i) => {
                return (
                  <li className="active-next" key={i}>
                    <a
                      href="#"
                      className="inactive"
                      onClick={() => changePage(n)}
                    >
                      {n}
                    </a>
                  </li>
                );
              })}

              <li className="not-allow">
                <span>
                  <li
                    className="next-prev"
                    onClick={() => {
                      nextpage();
                    }}
                  >
                    &gt;{" "}
                  </li>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Delet feedbacks  */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="fs-4" style={{ color: "red" }}>
                Are you sure?
                <br /> you want to delete this data?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="modal-close-btn" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={DeleteFeedback}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserReview;
