import React, { useState } from "react";
import axios from "axios";

function AdminLogin() {
  const [REmail, setREmail] = useState("");
  const [RPassword, setRPassword] = useState("");
  const adminLogin = async () => {
    try {
      const config = {
        url: "admin/adminLogin",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        headers: { "content-type": "application/json" },
        data: { REmail: REmail, RPassword: RPassword },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully login");
        window.sessionStorage.setItem(
          "admin",
          JSON.stringify(res.data.success)
        );
        window.location.assign("/admin_banner");
      }
    } catch (error) {
      alert("Please Enter Registered Email ID or Password");
      console.log(error);
    }
  };

  // passwrd view icon 
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  return (
    <>
      <div className="bg-img1">
        <div className="add">
          <div className="container">
            <div className="fw_90">
              <div className="add_0">
                <div className="im09">
                  <div className="d-flex">
                   
                      <img
                        src="../Assets/logo.png"
                        alt="Logo"
                        style={{ marginLeft: "auto", marginRight: "auto",height:'150px' }}
                        className="admin-login-logo"
                      />
                   
                  </div>
                </div>
                <div className="add-90">
                  <form>
                    <div className="sd_00 mb-2">
                      <label>Email</label> <br />
                      <input
                        type="email"
                        placeholder="email@gmail.com"
                        className="name_0"
                        value={REmail}
                        onChange={(e) => setREmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="sd_00 mb-2">
                      <label>Password</label>
                      <br />
                      <div style={{ position: "relative" }}>
                        <input
                          type={passwordVisible ? "text" : "password"} // Toggle input type
                          placeholder="password"
                          className="name_0"
                          value={RPassword}
                          onChange={(e) => setRPassword(e.target.value)}
                          required
                          style={{ paddingRight: "40px" }}
                        />
                        <span
                          onClick={() => setPasswordVisible(!passwordVisible)} // Toggle visibility
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          {passwordVisible ? "👁️" :  "🙈"}
                        </span>
                      </div>
                    </div>
                    <div className="sd_00 mt-2">
                      {" "}
                      <button
                        type="button"
                        style={{ background: "white", color: "black" }}
                        onClick={() => adminLogin()}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
