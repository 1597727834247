import React, { useState, useEffect } from "react";
import "../Styles/contact.css";
import { Container } from "react-bootstrap";
import { IoMdMail } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import axios from "axios";
import Aos from "aos";
import { PiArrowUp } from "react-icons/pi";

const Contactus = () => {
  useEffect(() => {
    Aos.init();
  });

  // Scrool window icon

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  // Email formate Validation
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (value) => {
    // Regex pattern for validating an email address
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(value);
  };

  // Phone number validation
  const [PlayerPhone, setPlayerPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (number) => {
    // Basic validation: Ensure it's a 10-digit number
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPlayerPhone(value);
    setIsPhoneValid(validatePhoneNumber(value));
  };

  // name validation
  const [isNameValid, setIsNameValid] = useState(true);

  const validateName = (name) => {
    // Regular expression to allow only letters, spaces, and hyphens
    const namePattern = /^[a-zA-Z\s-]+$/;
    return namePattern.test(name);
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setGUName(name);

    // Validate name
    if (validateName(name)) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  };

  // ====================CONTACT US Address, Eamil, Contact==========================//
  //integrating get  method contact us
  const [Addcontactus, setAddcontactus] = useState([]);
  const getAddcontactus = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getcontactus"
      );
      if (res.status === 200) {
        setAddcontactus(res.data.getcontactus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddcontactus();
  }, []);

  // ====================CONTACT US FORM=========================//
  // post method form
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formdata = new FormData();
  const [GUName, setGUName] = useState("");
  const [GUPhone, setGUPhone] = useState("");
  const [GUEmail, setGUEmail] = useState("");
  const [GUMessage, setGUMessage] = useState("");
  const [QueryDate, setQueryDate] = useState("");

  const Addgeneralquery = async () => {
    try {
      if (!GUName) {
        return alert("Please add name");
      }

      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!GUEmail || !emailPattern.test(GUEmail)) {
        return alert("Please add a valid email address");
      }

      const phonePattern = /^[6-9]\d{9}$/;
      if (!GUPhone || !phonePattern.test(GUPhone)) {
        return alert("Please add a valid 10-digit phone number");
      }

      if (!GUMessage) {
        return alert("Please add a message");
      }

      // Generate the current date in the format 17-August-2024
      const currentDate = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });

      const config = {
        url: "/user/general",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          GUName: GUName,
          GUPhone: GUPhone,
          GUEmail: GUEmail,
          GUMessage: GUMessage,
          QueryDate: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getgeneral();
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get  method
  const [Addgeneral, setAddgeneral] = useState([]);
  const getgeneral = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/user/getgeneral"
      );
      if (res.status === 200) {
        setAddgeneral(res.data.getgeneral);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getgeneral();
  }, []);
  console.log(Addgeneral);

  // ====================Social Media=========================//
  //integrating get  method
  const [Addsocial, setAddsocial] = useState([]);
  const getAddsocial = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getsocial"
      );
      if (res.status === 200) {
        setAddsocial(res.data.getsocial);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddsocial();
  }, []);
  console.log(Addsocial);

  return (
    <div>
      <div className="position-relative">
        <img
          src="../Assets/contactbg2.jpg"
          alt=""
          className="bg-img R-contactus-bg"
        />
        <div>
          {/* <h2 className="titlehj R-contactus-title">CONTACT US</h2> */}
        </div>
        <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/66qhiu"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
              />
              <span className="tooltip-text">Let's chat</span>
            </a>
          </div>
        </div>

        {/* scrooling up icon  */}
        <div>
          <div
            className="scrool-up-icon"
            style={{ display: visible ? "inline" : "none" }}
            onClick={scrollToTop}
          >
            <div className="scrool">
              <PiArrowUp style={{ fontSize: "20px" }} />
            </div>
          </div>
        </div>
      </div>

      <Container style={{}}>
        <div className="row mt-3 mb-4 R-contact-form  ">
          <div className="col-md-6">
            <div className="form-container">
              <h2>Let's Connect</h2>

              <div>
                <input
                  type="text"
                  placeholder="Enter Your Name *"
                  className="vi_1 mb-2"
                  required
                  value={GUName}
                  onChange={handleNameChange}
                />
                {!isNameValid && (
                  <p style={{ color: "red" }}>
                    Please enter a valid name (only letters ).
                  </p>
                )}
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Your Email *"
                  className="vi_1 mb-2"
                  required
                  onChange={(e) => setGUEmail(e.target.value)}
                />
                {!isEmailValid && (
                  <p style={{ color: "red" }}>
                    Please enter a valid email address.
                  </p>
                )}
              </div>

              <div>
                <input
                  type="number"
                  id="number"
                  name="number"
                  placeholder="+91 Enter Contact Number *"
                  className="vi_1 mb-2"
                  required
                  onChange={(e) => setGUPhone(e.target.value)}
                />
                {!isPhoneValid && (
                  <p style={{ color: "red" }}>
                    Please enter a valid 10-digit phone number.
                  </p>
                )}
              </div>

              <textarea
                type="text"
                placeholder="Enter Your Message *"
                className="textarea mb-2"
                required
                onChange={(e) => setGUMessage(e.target.value)}
              />
              <button className="send-button" onClick={Addgeneralquery}>
                Submit
              </button>
            </div>
          </div>

          <div className="col-md-5 p-2">
            <div
              className=" mt-3 mb-3 R-contact-loc"
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <h3 className="mb-3">Follow us</h3>
              <div
                className="contact-info-container-social-icon justify-content-start mb-3"
                style={{ gap: "8px" }}
              >
                <div className="row" style={{ gap: "12px" }}>
                  {Addsocial?.map((val, i) => {
                    return (
                      <div className="icon-section">
                        <a href={val.CLink} target="_new">
                          <img
                            src={`https://shuttlesmash.shop/SocialMedia/${val?.CIcon}`}
                            alt=""
                            className="s-img"
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {Addcontactus?.map((val, i) => {
              return (
                <div>
                  <div
                    className="d-flex gap-3 mb-3 mt-5"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    <div>
                      <FaLocationDot style={{ fontSize: "22px" }} />
                    </div>
                    <div>{val.CAddress}</div>
                  </div>
                  <hr />
                  <div
                    className="d-flex gap-3 mb-3"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    <div>
                      <IoMdMail style={{ fontSize: "22px" }} />
                    </div>
                    <div> {val.CEmail}</div>
                  </div>
                  <hr />
                  <div
                    className="d-flex gap-3 mb-3"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    <div>
                      <BiSolidPhoneCall style={{ fontSize: "22px" }} />
                    </div>
                    <div>+91 {val.CPhone}</div>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Contactus;
