import React, { useState, useEffect } from "react";
import "../Styles/events.css";
import { Container, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { FaArrowLeft } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const Registration = () => {
  const location = useLocation();
  const { item } = location.state || {};
  console.log("item>>>>>>>>>>>>>", item);
  // Email formate Validation
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (value) => {
    // Regex pattern for validating an email address
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  // Phone number validation
  const [PlayerPhone, setPlayerPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (number) => {
    // Basic validation: Ensure it's a 10-digit number
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPlayerPhone(value);
    setIsPhoneValid(validatePhoneNumber(value));
  };

  // Name validation
  const validateNameInput = (nameInput) => {
    return nameInput.replace(/[^a-zA-Z\s]/g, "");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    sessionStorage.removeItem("playerData");
    // setPlayerPhoneNo("");
    // setPlayerEmail("");
    // setPlayerNames({});
    // setPlayerDetailsShow([])
  };
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [AddCategory, setAddCategory] = useState([]);
  const getAddCategory = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getcategory"
      );
      if (res.status === 200) {
        setAddCategory(res.data.getcategory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddCategory();
  }, []);

  const [selectedCategories, setSelectedCategories] = useState({
    sum: 0,
    items: [],
    itemsId: [],
  });

  const handleCheckboxChange = (value, checked, item) => {
    console.log("ghghgghgh: ", item);
    setSelectedCategories((prev) => {
      const newSum =
        (prev.sum || 0) + (checked ? Number(value) : -Number(value));

      const newItems = checked
        ? [...prev.items, item]
        : prev.items.filter((i) => i._id !== item._id);

      const newItemsId = checked
        ? [...prev.itemsId, { categoryId: item._id }]
        : prev.itemsId.filter((idObj) => idObj.categoryId !== item._id);

      if (newItems.length === 0) {
        setPlayerEmail("");
        setPlayerPhoneNo("");
      }

      if (!checked) {
        console.log("unchecking: ", item, prev.items.indexOf(item));
        clearPlayerDetailsForCategory(item, item._id);
        console.log(
          "check------->",
          item,
          "value checking..",
          prev.items.indexOf(item)
        );
      }
      return { sum: newSum, items: newItems, itemsId: newItemsId };
    });
  };

  // Function to clear player details for a category based on the inputIndex
  const clearPlayerDetailsForCategory = (category, categoryIndex) => {
    setPlayerDetailsShow((prevDetails) =>
      prevDetails.filter(
        (detail) => !detail.inputIndex.startsWith(`${categoryIndex}_`)
      )
    );

    // Also clear player names from the playerNames state
    setPlayerNames((prevNames) => {
      const updatedNames = { ...prevNames };
      Object.keys(updatedNames).forEach((key) => {
        if (key.startsWith(`${categoryIndex}_`)) {
          delete updatedNames[key];
        }
      });
      return updatedNames;
    });
  };

  let TotalSelectedCategory = selectedCategories?.items;
  let TotalSelectedCategoryIds = selectedCategories?.itemsId;
  console.log("TotalSelectedCategory===>", TotalSelectedCategory);
  console.log("selectedCategories===>", selectedCategories);

  let subtotal = 0;

  const [PlayerDetailsShow, setPlayerDetailsShow] = useState([]);
  console.log("PlayerDetailsShow", PlayerDetailsShow);

  const setStorePlayerDetailscat = (inputIndex, playerName, categoryName) => {
    setPlayerDetailsShow((prevDetails) => {
      // Filter out any details that are no longer valid due to a reduction in TotalPlayers
      let updatedDetails = prevDetails.filter((detail) => {
        const [categoryIdx, playerIdx] = detail.inputIndex
          .split("_")
          .map(Number);
        const category = TotalSelectedCategory[categoryIdx];
        return category && playerIdx < category.TotalPlayers;
      });

      // Check if the entry already exists
      const existingIndex = updatedDetails.findIndex(
        (detail) => detail.inputIndex === inputIndex
      );

      if (existingIndex > -1) {
        // Update existing entry with new player name or clear it if the playerName is empty
        updatedDetails[existingIndex] = {
          inputIndex,
          name: playerName,
          categoryName,
        };
      } else if (playerName.trim()) {
        // Add new entry only if playerName is not empty
        updatedDetails.push({ inputIndex, name: playerName, categoryName });
      }

      // Clear data if the player count was reduced and inputIndex no longer valid
      updatedDetails = updatedDetails.map((detail) => {
        const [categoryIdx, playerIdx] = detail.inputIndex
          .split("_")
          .map(Number);
        const category = TotalSelectedCategory[categoryIdx];
        if (!category || playerIdx >= category.TotalPlayers) {
          return { ...detail, name: "" }; // Clear the name
        }
        return detail;
      });

      return updatedDetails;
    });
  };

  if (TotalSelectedCategory?.length !== 0) {
    for (let i = 0; i < TotalSelectedCategory.length; i++) {
      subtotal += TotalSelectedCategory[i]?.CategoryPrice;
    }
  }

  let Totalnumberfoplayer = selectedCategories.sum;
  let TextfieldArray = Array(Totalnumberfoplayer).fill(Totalnumberfoplayer);

  const [playerNames, setPlayerNames] = useState({});
  const handlePlayerNameChange = (index, name) => {
    console.log("index: bhai", index);
    setPlayerNames((prev) => ({ ...prev, [index]: name }));
  };

  console.log("set: ===> ", playerNames);

  const navigate = useNavigate();

  const [PlayerEmail, setPlayerEmail] = useState("");
  const [PlayerPhoneNo, setPlayerPhoneNo] = useState("");

  const [RegisteredDate, setRegisteredDate] = useState("");
  const [RegisteredTime, setRegisteredTime] = useState("");

  const AddRegisterquery = async () => {
    try {
      // Generate the current date in the format 17-August-2024
      const currentDate = new Date();

      // Generate the current time
      const currentTime = moment().format("hh:mm:A");

      const config = {
        url: "/user/registration",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          eventsId: item,
          playerNames: playerNames,
          PlayerEmail: PlayerEmail,
          PlayerPhoneNo: PlayerPhoneNo,
          TotalAmount: subtotal,
          Category: TotalSelectedCategoryIds,
          RegisteredDate: currentDate,
          RegisteredTime: currentTime,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        sendmail();
        handleClose1();
        handleClose();
        setPlayerNames("");
        setPlayerEmail("");
        setPlayerPhoneNo("");
        playerNamesArray.length = 0;
        sessionStorage.removeItem("playerData");
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obj = {
    playerNames: playerNames,
    PlayerEmail: PlayerEmail,
    PlayerPhoneNo: PlayerPhoneNo,
    TotalAmount: subtotal,
    playesdetails: selectedCategories?.items,
    PlayerDetailsShow: PlayerDetailsShow,
  };
  const storesession = () => {
    try {
      if (TotalSelectedCategory.length === 0) {
        return alert("Please select at least one category.");
      }

      for (let i = 0; i < TotalSelectedCategory.length; i++) {
        const category = TotalSelectedCategory[i];
        for (let j = 0; j < category.TotalPlayers; j++) {
          const inputIndex = `${category?._id}_${j}`;
          if (
            !playerNames[inputIndex] ||
            playerNames[inputIndex].trim() === ""
          ) {
            return alert(
              `Please enter name for Player ${j + 1} in ${
                category.CategoryName
              }.`
            );
          }
        }
      }
      // Check if email is entered
      if (!PlayerEmail) {
        return alert("Please Enter Email");
      }
      if (!isEmailValid) {
        return alert("Please enter a valid email.");
      }
      if (!PlayerPhoneNo) {
        return alert("Please Enter Phone Number");
      }
      if (!isPhoneValid) {
        return alert("Please enter a valid 10-digit phone number.");
      }
      sessionStorage.setItem("playerData", JSON.stringify(obj));
      handleShow();
    } catch (error) {
      console.error("Error storing session:", error);
    }
  };

  const playerData = JSON.parse(sessionStorage.getItem("playerData"));
  const playerNamesArray = playerData?.playerNames
    ? Object.values(playerData.playerNames)
    : [];

  const sendmail = async () => {
    try {
      const config = {
        url: "user/sendmail",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          PlayerEmail: playerData?.PlayerEmail,
          playerNames: playerNamesArray,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ===============QR CODE===============//

  //integrating get  method
  const [Dat, setDat] = useState("");
  const [AddQrcode, setAddQrcode] = useState([]);
  const getAddQrcode = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getqrcode"
      );
      if (res.status === 200) {
        setAddQrcode(res.data.getqrcode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddQrcode();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#004aad",
        backgroundImage: "url('../backd.avif')",
        backgroundSize: "cover",
        height: "100%",
      }}
    >
      <div className="regiter-bg pb-3">
        <div className="d-flex gap-4 align-items-center justify-content-center">
          <div>
            <h2 className="headdings mt-3 mb-4 " style={{ color: "white" }}>
              Registration Form
            </h2>
          </div>
        </div>

        <Container>
          <div className="Registration-main-box ">
            <div className="Registration-box mb-3">
              <div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>
                      Select Category <span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      style={{ padding: "8px", borderRadius: "5px" }}
                      className="form-control"
                    >
                      {item?.EventCategory?.map((item, i) => (
                        <div key={i}>
                          <input
                            type="checkbox"
                            id={`Category-${i}`}
                            value={item?.categoryid?.TotalPlayers}
                            onChange={(e) => {
                              handleCheckboxChange(
                                e.target.value,
                                e.target.checked,
                                item.categoryid
                              );
                            }}
                          />{" "}
                          <span htmlFor={`Category-${i}`}>
                            {item.categoryname}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {TotalSelectedCategory?.map((item1, categoryIndex) => {
                  return (
                    <div key={categoryIndex}>
                      <h3
                        className="selected-catname"
                        style={{
                          textAlign: "center",
                          color: "blue",
                          fontSize: "22px",
                          margin: "10px 0px",
                        }}
                      >
                        {item1?.CategoryName}
                      </h3>
                      {[...Array(item1.TotalPlayers)].map((_, i) => {
                        const inputIndex = `${item1?._id}_${i}`;
                        return (
                          <div key={inputIndex} className="do-sear">
                            <label>
                              Player Name
                              {[...Array(item1.TotalPlayers)].length > 1
                                ? i + 1
                                : ""}{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id={`Name${inputIndex}`}
                              name={`Name${inputIndex}`}
                              placeholder="Enter Your Name"
                              className="vi_0"
                              required
                              onChange={(e) => {
                                const validName = validateNameInput(
                                  e.target.value
                                );

                                handlePlayerNameChange(
                                  inputIndex,
                                  validName,
                                  e.target.value
                                );
                                setStorePlayerDetailscat(
                                  inputIndex,
                                  e.target.value,
                                  item1?.CategoryName
                                );
                              }}
                              value={playerNames[inputIndex] || ""}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                <div>
                  <div className="do-sear mt-2">
                    <label>
                      Email ID <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      id="PlayerEmail"
                      name="PlayerEmail"
                      value={PlayerEmail}
                      placeholder="Enter Your Email"
                      className="vi_0"
                      required
                      style={{ borderColor: isEmailValid ? "" : "red" }}
                      onChange={(e) => {
                        setPlayerEmail(e.target.value);
                        handleEmailChange(e);
                      }}
                    />
                    {!isEmailValid && (
                      <p style={{ color: "red" }}>
                        Please enter a valid email address.
                      </p>
                    )}
                  </div>
                  <div className="do-sear mt-2">
                    <label>
                      Contact Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="PlayerPhoneNo"
                      name="PlayerPhoneNo"
                      placeholder="+91 Enter Contact Number"
                      className="vi_0"
                      value={PlayerPhoneNo}
                      required
                      style={{ borderColor: isPhoneValid ? "" : "red" }}
                      onChange={(e) => {
                        setPlayerPhoneNo(e.target.value); // Updates state with the new email value
                        handlePhoneChange(e); // Validates the email
                      }}
                    />
                    {!isPhoneValid && (
                      <p style={{ color: "red" }}>
                        Please enter a valid 10-digit phone number.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-3 mb-3">
                <div>
                  <a href="/tournaments" style={{ margin: "0" }}>
                    <Button className="mx-2 modal-add-btn" variant="">
                      Back
                    </Button>
                  </a>
                </div>
                <div>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                    onClick={() => {
                      storesession();
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* REGISTERED USER TABLE   */}
      <Modal
        show={show}
        onHide={handleClose}
        style={{ zIndex: "99999" }}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}> </Modal.Title>{" "}
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              margin: "auto",
              // textDecoration: "underline",
              textAlign: "center",
            }}
          >
            <div>
              <h3>
                <b>Registered Summary</b>{" "}
              </h3>
            </div>
          </div>
          <div className="row mt-3 mb-2">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>SL.NO</th>
                  <th>Category</th>
                  <th>Player Name 1</th>
                  <th>Player Name 2</th>
                </tr>
              </thead>
              <tbody>
                {/* {Object.entries(
                  PlayerDetailsShow.reduce((acc, item) => {
                    if (!acc[item.categoryName]) {
                      acc[item.categoryName] = [];
                    }
                    acc[item.categoryName].push(item.name);
                    return acc;
                  }, {})
                ).map(([categoryName, playerNames], index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{categoryName}</td>
                    <td>{playerNames[0] || ""}</td>
                    <td>{playerNames[1] || ""}</td>
                  </tr>
                ))} */}

                {playerData?.playesdetails?.map((valdata, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{valdata.CategoryName}</td>
                      <td>{playerNames[`${valdata._id}_0`]}</td>
                      <td>
                        {playerNames[`${valdata._id}_1`] ? (
                          playerNames[`${valdata._id}_1`]
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <div className="row">
              <div className="col-md-12 mb-2">
                <b> Email ID: - </b> <span> {playerData?.PlayerEmail}</span>
              </div>
              <div className="col-md-12 mb-2">
                <b> Contact No: - </b>{" "}
                <span> +91 {playerData?.PlayerPhoneNo}</span>
              </div>
            </div>

            <div className="row mt-3 mb-2">
              <div className="col-md-6">
                <Table striped bordered hover responsive size="sm">
                  <thead>
                    <tr>
                      <th>Category Name</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {playerData?.playesdetails?.map((item) => {
                      return (
                        <tr>
                          <td>{item?.CategoryName}</td>
                          <td>{item?.CategoryPrice}</td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>{playerData?.TotalAmount}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 justify-content-center">
            <Button
              variant=""
              className=" mx-2 modal-add-btn"
              onClick={handleClose}
            >
              Back
            </Button>

            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={() => {
                // handleClose();
                handleShow1();
              }}
            >
              Pay Now
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/*  Payment Modal  */}
      <Modal
        show={show1}
        onHide={handleClose1}
        style={{ zIndex: "99999" }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}> </Modal.Title>{" "}
          <div style={{ margin: "auto" }}>
            <div>
              <h4>
                <b>Selected Category Entry Fees </b> <br />
                <b
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "2px 5px",
                  }}
                >
                  Rs {playerData?.TotalAmount}
                </b>
              </h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <b
              style={{
                color: "green",
                fontSize: "25px",
                fontWeight: "bold",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              *Important*
            </b>
          </div>
          <div className="">
            <div style={{ fontWeight: "bold" }}>
              <div className="mt-2 mb-2">
                1. After making a successful payment, please share the
                screenshot to our WhatsApp number{" "}
                <a
                  href="http://wa.link/5s5pya"
                  style={{ fontSize: "14px", textDecoration: "none" }}
                >
                  <b>+91 8861711005. </b>
                </a>
                Your entry will only be considered for the fixtures once we
                receive the payment confirmation.
              </div>
              <div className="mb-2">
                2. If You are facing any payment issue please contact us +91
                8861711005
              </div>
            </div>
          </div>
          <div className="col-md-6 m-auto">
            {AddQrcode.map((item, i) => {
              return (
                <div>
                  <img
                    src={`https://shuttlesmash.shop/Events/${item?.QrcodeImage}`}
                    alt="pic"
                    style={{ width: "100%" }}
                  />
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              style={{
                backgroundColor: "#004aad !important",
                borderColor: "#004aad",
              }}
              variant="primary"
              onClick={handleClose1}
            >
              Cancel
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              style={{ color: "#004aad" }}
              variant=""
              onClick={AddRegisterquery}
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Registration;
