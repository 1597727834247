import React, { useState, useEffect } from "react";
import "../Styles/gallery.css";
import { Container, Button, Card, Modal, Row } from "react-bootstrap";
import axios from "axios";
import Aos from "aos";
import { PiArrowUp } from "react-icons/pi";

const Gallary = () => {
  useEffect(() => {
    Aos.init();

  });

    // Scrool window icon 

    const [visible, setVisible] = useState(false) 
  
    const toggleVisible = () => { 
      const scrolled = document.documentElement.scrollTop; 
      if (scrolled > 300){ 
        setVisible(true) 
      }  
      else if (scrolled <= 300){ 
        setVisible(false) 
      } 
    }; 
    
    const scrollToTop = () =>{ 
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
      }); 
    }; 
    
    window.addEventListener('scroll', toggleVisible);

  //integrating get  method
  const [View, setView] = useState({});
  const [AddGallery, setAddGallery] = useState([]);
  const getAddGallery = async () => {
    try {
      let res = await axios.get("https://shuttlesmash.shop/api/admin/getgallery");
      if (res.status === 200) {
        // Sort by a date field, such as `createdAt` or `_id`
        const sortedGallery = res.data.getgallery.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAddGallery(sortedGallery);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    getAddGallery();
  }, []);

  //integrating get  method
  const [AddGalleryvideo, setAddGalleryvideo] = useState([]);
  const getAddGalleryvideo = async () => {
    try {
      let res = await axios.get("https://shuttlesmash.shop/api/admin/getgalleryvedio");
      if (res.status === 200) {
        // Sort by date (createdAt or other date field) or ObjectId (_id)
        const sortedVideos = res.data.getgalleryvedio.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAddGalleryvideo(sortedVideos);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    getAddGalleryvideo();
  }, []);
  const handleDownload = (data, filename) => {
    const blob = new Blob([data], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const ImageCard = ({ j, itemd }) => {
    const imageUrl = `https://shuttlesmash.shop/Gallery/${itemd?.placepicture}`;

    // Fetch the image data for demonstration purposes
    const fetchImageData = async () => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        handleDownload(blob, itemd?.placepicture);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    return (
      <div key={j} className="col-md-4 mb-2">
        <img
          src={imageUrl}
          alt="pic"
          style={{ width: "100%", height: "300px" }}
          onClick={fetchImageData}
        />
      </div>
    );
  };

  return (
    <div>
      <div>
        <img
          src="../Assets/gallerybg1.jpg"
          alt=""
          className="bg-img R-gallery-bg"
        />
        <div>
          {/* <h2 className="titlegfh  R-gallery-title">GALLERY</h2> */}
        </div>
        <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
            href="https://wa.link/66qhiu"
            target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
              />
              <span className="tooltip-text">Let's chat</span>
            </a>
          </div>
        </div>

            {/* scrooling up icon  */}
            <div>
        <div className="scrool-up-icon"  style={{display: visible ? 'inline' : 'none'}} onClick={scrollToTop}>
          <div className="scrool">
            <PiArrowUp style={{ fontSize: '20px' }} />
          </div>
        </div>
    
    </div>

      </div>

      <Container>
           {/* VIDEO  */}
           <div>
          <div>
            <div className="row mt-5 mb-5">
              {AddGalleryvideo?.map((items, i) => {
                return (
                  <div className="col-md-4 mb-2">
                    <video
                      src={`https://shuttlesmash.shop/Gallery/${items?.GalleryVedio}`}
                      alt="video"
                     controls
                      style={{ width: "100%", height: "300px" }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      <hr />

      {/* IMAGE  */}
        <div
          className="row mt-5 mb-3"
          data-aos="zoom-in"
          data-aos-delay="50"
          data-aos-duration="3000"
        >
          <div>
            <div className="row">
              {AddGallery?.map((items, i) => {
                return (
                  <div key={i} className="col-md-12">
                    <h1 style={{ textAlign: "center" }}>
                      {items.GalleryTitle}
                    </h1>
                    <div className="row mt-4 mb-3">
                      {items?.PlaceImages?.map((itemd, j) => {
                        return <ImageCard j={j} itemd={itemd} />;
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      
     
      </Container>
    </div>
  );
};

export default Gallary;
