import React, { useState, useEffect, useRef } from "react";
import "../Styles/home.css";
import bannervedio from "../../src/homebanner1.mp4";
import { IoLogoWhatsapp } from "react-icons/io";
import { Container, Button, Card, Modal } from "react-bootstrap";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import parse from "html-react-parser";
import axios from "axios";
import Aos from "aos";
import { TypeAnimation } from "react-type-animation";
import { PiArrowUp } from "react-icons/pi";
import { FaDownload } from "react-icons/fa";
import { saveAs } from "file-saver";

const Home = () => {
  useEffect(() => {
    Aos.init();
  });

  // Scrool window icon
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  // DATE & MONTH FORMATE
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { day: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Type animation Color

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1, // Show 1 item at a time
      slidesToSlide: 1, // Slide 1 item at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const responsive1 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // Corrected to 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // Corrected to 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // Corrected to 1
    },
  };

  // ===============HOME BANNER================== //

  //integrating get  method
  const [AddBanner, setAddBanner] = useState([]);
  const getAddBanner = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getbanner"
      );
      if (res.status === 200) {
        setAddBanner(res.data.getbanner);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddBanner();
  }, []);

  // ===============HOME EVENTS================== //

  //integrating get  method
  const [AddEvents, setAddEvents] = useState([]);
  console.log("AddEvents", AddEvents);
  const getAddEvents = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getevents"
      );
      if (res.status === 200) {
        setAddEvents(res.data.getevents);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddEvents();
  }, []);

  // ===============HOME SPONSORS================== //

  //integrating get  method
  const [AddPartner, setAddPartner] = useState([]);
  const getAddPartner = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getpartner"
      );
      if (res.status === 200) {
        setAddPartner(res.data.getpartner);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddPartner();
  }, []);

  // ===============HOME TESTIMONIAL================== //

  //integrating get  method
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonial = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/gettestimonial"
      );
      if (res.status === 200) {
        setAddTestimonial(res.data.gettestimonial);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddTestimonial();
  }, []);

  // Read More
  const [showModal, setShowModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");

  const handleReadMore = (description) => {
    setCurrentDescription(description);
    setShowModal(true);
  };

  const truncateText = (text) => {
    const maxLength = 300; // Approximate character limit for three lines
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  // =============== BROCHURE DOWNLOAD================== //

  //integrating get  method EVENTS BANNER
  const [AddBrochure, setAddBrochure] = useState([]);
  const getAddBrochure = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getbrochures"
      );
      if (res.status === 200) {
        setAddBrochure(res.data.getbrochures);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddBrochure();
  }, []);

  return (
    <div>
      {/* HOME BANNER  */}
      <div>
        {AddBanner?.map((val, i) => {
          return (
            <div className="first-banner-component">
              <video
                // src={bannervedio}
                src={`https://shuttlesmash.shop/Homebanner/${val?.BannerImage}`}
                autoPlay
                loop
                muted
                className="home-banner"
              />

              <div className="caption-display">
                <div
                  data-aos="fade-right"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <TypeAnimation
                    className="caption res-caption"
                    style={{
                      height: "250px",
                      width: "100%",
                      display: "block",
                      color: "white",
                      textShadow: "2px 2px navy",
                      whiteSpace: "pre-line", // This ensures that newline characters are rendered properly
                    }}
                    sequence={[
                      val?.BannerText || "", // First text
                      2000, // Duration to display the first text

                      `${val?.BannerText2 || ""}\n${val?.BannerText3 || ""}`, // Second and third text with a line break
                      2000, // Duration to display the combined text

                      val?.BannerTagline || "", // Fourth text
                      2000, // Duration to display the fourth text
                    ]}
                    speed={200} // Typing speed in ms per character
                    cursor={false}
                    deletionSpeed={false} // No deletion speed (prevents text from being deleted)
                    repeat={Infinity} // Repeat the sequence indefinitely
                    omitDeletionAnimation={true}
                  />
                </div>
              </div>
            </div>
          );
        })}
        {/* fixed icons  */}
        <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/66qhiu"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
              />
              <span className="tooltip-text">Let's chat</span>
            </a>
          </div>
        </div>

        {/* scrooling up icon  */}
        <div>
          <div
            className="scrool-up-icon"
            style={{ display: visible ? "inline" : "none" }}
            onClick={scrollToTop}
          >
            <div className="scrool">
              <PiArrowUp style={{ fontSize: "20px" }} />
            </div>
          </div>
        </div>
      </div>

      {/* UPCOMING EVENTS  */}
      <div className=" mb-5">
        <Container>
          <div>
            <h2 className="headdings " style={{ fontSize: "30px" }}>
              {" "}
              Upcoming Tournaments
            </h2>
          </div>
        </Container>{" "}
        <Container className="" data-aos="zoom-in" data-aos-duration="3000">
          <div className="row ">
            <Carousel
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all 0.5s ease"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              showDots={true}
              arrows={false}
            >
              {AddEvents?.filter((ele) => ele.isBlock === false)?.map(
                (val, i) => (
                  <div className="item" key={i}>
                    <a
                      href="/tournaments"
                      style={{ color: "unset", textDecoration: "none" }}
                    >
                      <div className="card events-crd">
                        <Card.Img
                          src={`https://shuttlesmash.shop/Events/${val?.EventImage}`}
                          className="events-crd-img"
                        />
                        <Card.Body>
                          <div className="upcoming-date">
                            {val.EventStartdate}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <p>
                                <b>{val.EventName} </b>
                              </p>
                            </div>
                            <div>
                              <Button>
                                View More{" "}
                                <span>
                                  <FaArrowAltCircleRight
                                    className="shake"
                                    style={{ fontSize: "20px" }}
                                  />
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </div>
                    </a>
                  </div>
                )
              )}
            </Carousel>
          </div>
        </Container>
      </div>

      {/* DOWNLOAD BROCHURE  */}
      <div data-aos="fade-up" data-aos-duration="3000">
        {AddBrochure?.map((val, i) => {
          return (
            <div className="container brochure-section text-center p-4 mt-4 position-relative">
              {/* <img
                src="../Assets/badmintonleftsmall.png" // Replace with your actual image path
                alt="Decorative cross"
                className="cross-img1 res-img-behind"
              /> */}
              <h4 className="mb-3">
                "To know more about Tournament, please download the flyer"
              </h4>
              <Button
                variant=""
                className="brochure-download-btn px-4 py-2"
                onClick={async () => {
                  const fileUrl = `https://shuttlesmash.shop/Events/${val?.Brochure}`;
                  try {
                    const response = await fetch(fileUrl);
                    const blob = await response.blob();
                    saveAs(blob, val?.Brochure); // This triggers the download directly
                  } catch (error) {
                    console.error("Failed to download file:", error);
                  }
                }}
              >
                <FaDownload style={{ margin: "0px 4px" }} />
                Download Flyer
              </Button>
              {/* <img
                src="../Assets/badmintonrightsmall.png" // Replace with your actual image path
                alt="Decorative cross"
                className="cross-img res-img-behind"
              /> */}
            </div>
          );
        })}
      </div>

      {/* OUR SPONSORS  */}
      <div className="mt-5 mb-5 R-margins">
        <Container>
          <div>
            <h2
              className="headdings mb-5 R-margins"
              style={{ fontSize: "30px" }}
            >
              Our Partners
            </h2>
          </div>
        </Container>
        <Container
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="3000"
        >
          <Carousel
            responsive={responsive1}
            margin={10}
            autoPlay={true}
            infinite={true}
            autoPlaySpeed={2000}
            transitionDuration={500}
            arrows={false}
            showDots={true}
          >
            {AddPartner?.map((val, i) => {
              return (
                <div className="item d-flex justify-content-center ">
                  <div className="card sponsor-crd">
                    <a href={val.PartnerLink} target="_new">
                      <Card.Img
                        variant="top"
                        src={`https://shuttlesmash.shop/Partners/${val?.PartnerImage}`}
                        className="sponsor-crd-img object-fit-contain"
                      />
                    </a>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </Container>
      </div>

      {/* TESTIMONIALA  */}
      <div className="mt-5 mb-5">
        <div className="testimonial-bg">
          <div>
            <h2
              className="headdings pt-3 mb-4 text-center"
              style={{ color: "white", fontSize: "30px" }}
            >
              Testimonials{" "}
            </h2>
          </div>

          <Container
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="3000"
          >
            <Carousel
              responsive={responsive2}
              margin={0}
              autoPlay={true}
              infinite={true}
              autoPlaySpeed={6000}
              transitionDuration={500}
              arrows={false}
              showDots={false}
            >
              {AddTestimonial?.map((val, i) => {
                return (
                  <div className="item " key={i}>
                    <div className="card testimonial-crd p-1">
                      <div className="row justify-content-center p-0" >
                        <div>
                          <Container>
                            <div className="d-flex res-testimonial mt-5">
                              <FaQuoteLeft style={{ color: "#004aad" }} />
                              <Container>
                                <div className="mt-1 text-truncate-three-lines">
                                  {parse(truncateText(val.Description))}
                                  {val.Description.length > 300 && (
                                    <span
                                      className="read-more cursor-pointer"
                                      onClick={() =>
                                        handleReadMore(val.Description)
                                      }
                                    >
                                      Read More
                                    </span>
                                  )}
                                </div>
                              </Container>
                              <FaQuoteRight style={{ color: "#004aad" }} />
                            </div>
                          </Container>
                        </div>

                        <div className="ps-1 ms-5 mb-3">
                          <div>{val.Name}</div>
                          <div>{val.Designation}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Container>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>{parse(currentDescription)}</Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Home;
