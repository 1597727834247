import React, { useState, useEffect } from "react";
import "../Styles/footer.css";
import { IoMdMail } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdChevronRight } from "react-icons/md";
import { Container, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Footer = () => {
  // Review modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    // setGUMessage(data);
  };

  // Phone number validation
  const [PlayerPhone, setPlayerPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (number) => {
    // Basic validation: Ensure it's a 10-digit number
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPlayerPhone(value);
    setIsPhoneValid(validatePhoneNumber(value));
  };

  // ===============FOOTER ================== //
  //integrating get  method contact us
  const [Addcontactus, setAddcontactus] = useState([]);
  const getAddcontactus = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getcontactus"
      );
      if (res.status === 200) {
        setAddcontactus(res.data.getcontactus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddcontactus();
  }, []);

  // ====================Social Media=========================//
  //integrating get  method
  const [Addsocial, setAddsocial] = useState([]);
  const getAddsocial = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/admin/getsocial"
      );
      if (res.status === 200) {
        setAddsocial(res.data.getsocial);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddsocial();
  }, []);
  console.log(Addsocial);


  // =============FEED BACKS============================/

  // integrating post method
  const [UserName, setUserName] = useState("");
  const [UserPhoneNumber, setUserPhoneNumber] = useState("");
  const [UserLocation, setUserLocation] = useState("");
  const [FeedbackMessage, setFeedbackMessage] = useState("");
  const [FeedbackStatus, setFeedbackStatus] = useState("");
  const [FeedbackDate, setFeedbackDate] = useState("");

  const AddFeedbackdetaisl = async () => {
    try  {
      if (!UserName) {
        return alert("Please Enter Your Name");
      }
      if (!UserPhoneNumber) {
        return alert("Please Enter Your Contact Number ");
      }
      if (!UserLocation) {
        return alert("Please Enter Your Location");
      }
      if (!FeedbackMessage) {
        return alert("Please share Your Feedback");
      }
      if (!FeedbackStatus) {
        return alert("Please Choose yer or no")
      }

       // Generate the current date
    const currentDate = new Date().toLocaleDateString(); // Format as MM/DD/YYYY or modify as needed

      const config = {
        url: "/user/feedback",
        method: "post",
        baseURL: "https://shuttlesmash.shop/api/",
        header: { "content-type": "application/json" },
        data: {
          UserName: UserName,
          UserPhoneNumber: UserPhoneNumber,
          UserLocation: UserLocation,
          FeedbackMessage : FeedbackMessage,
          FeedbackStatus: FeedbackStatus,
          FeedbackDate: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddFeedback();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get  method
  const [AddFeedback, setAddFeedback] = useState([]);
  const getAddFeedback = async () => {
    try {
      let res = await axios.get(
        "https://shuttlesmash.shop/api/user/getfeedback"
      );
      if (res.status === 200) {
        setAddFeedback(res.data.getfeedback);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getAddFeedback();
  }, []);
  return (
    <div>
     
      <div className="footer-section w-100 ">
        <Container>
          <div className="row mt-4 mb-3 R-margins">
            <div className="col-md-3 mt-3 mb-3 R-margins">
              <div>
                <img
                  src="../Assets/logo.png"
                  alt="logo"
                  className="footer-logo"
                />
              </div>
            </div>

            <div
              className="col-md-3 R-margins footer-social-icon"
              style={{ display: "flex", marginTop:"38px" }}
            >
              <div className="quick">
                <h4 className="footer-title">Follow Us</h4>
                <ul>
                  <li>
                    <div className="row social-icon  " style={{ gap: "" }}>
                    {Addsocial?.map((val, i) => {
                        return (
                          <div className="icon-section">
                            <a href={val.CLink} target="_new">
                              <img
                                src={`https://shuttlesmash.shop/SocialMedia/${val?.CIcon}`}
                                alt=""
                                className="s-img"
                              />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 R-margins text-center" style={{marginTop:"38px"}}>
              <div className="quick">
                <h4 className="footer-title">Contact Us</h4>
                {Addcontactus?.map((val, i) => {
                  return (
                    <ul className="quick">
                      <li>
                        <div className="d-flex gap-2 mb-2">
                          <div>
                            <span>
                              <IoMdMail
                                style={{ color: "white", fontSize: "20px" }}
                              />
                            </span>
                          </div>
                          <div style={{ color: "white" }}>{val.CEmail}</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex gap-2 mb-3 R-margins">
                          <div>
                            <span>
                              <BiSolidPhoneCall
                                style={{ color: "white", fontSize: "20px" }}
                              />
                            </span>
                          </div>
                          <div style={{ color: "white" }}>+91 {val.CPhone}</div>
                        </div>
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>

            <div
              className="col-md-3 R-margins footer-social-icon"
              style={{ display: "flex", justifyContent: "center", marginTop:"38px" }}
            >
              <div className="quick">
                <h4 className="footer-title">Share Your Review</h4>
                <ul>
                  <li>
                    <div>
                      <Button onClick={handleShow}>Add Review</Button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* Review modal  */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>
            Share Your Review
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <input
                type="text"
                id="name"
                name="name"
                required
                placeholder="Enter Your Name *"
                className="vi_0"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>

            <div className="do-sear mt-2">
              <input
                type="number"
                id="number"
                name="number"
                required
                placeholder="Enter Your phone number *"
                className="vi_0"
                onChange={(e) => {
                  setUserPhoneNumber(e.target.value); 
                  handlePhoneChange(e); 
                }}              />
                 {!isPhoneValid && (
                    <p style={{ color: "red" }}>
                      Please enter a valid 10-digit phone number.
                    </p>
                  )}
            </div>

            <div className="do-sear mt-2">
              <input
                type="text"
                id="location"
                name="location"
                placeholder="Enter Your Location *"
                className="vi_0"
                onChange={(e) => setUserLocation(e.target.value)}
              />
             
            </div>

            <div className="do-sear mt-2">
              <textarea
                type="text"
                placeholder="Enter Your Message *"
                className="textarea mb-2"
                required
                onChange={(e) => {
                  setFeedbackMessage(e.target.value);
                }}
              />
            </div>
            <div>
              <FormControl required>
                <h6>
                  This Message Should Display on the Website?{" "}
                  <span style={{ color: "red" }}>*</span>
                </h6>{" "}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Null"
                  name="radio-buttons-group"
                >
                
                <div className="d-flex gap-5"  onChange={(e) => {
                  setFeedbackStatus(e.target.value);
                }} >
                <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"

                  />
                </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={AddFeedbackdetaisl}
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Footer;
